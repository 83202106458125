import React, { useState } from "react"
import Layout from "../components/shared/layout"
import SEO from "../components/seo"
import FilterBar from "../components/shared/filterBar"
import { graphql } from "gatsby"
import { getNextFilter, getCards } from "../utils/helper"
import "../components/styles/jobs.scss"

export const query = graphql`
  {
    prismic {
      allJobs(lang: "en-ca") {
        edges {
          node {
            address
            city
            province
            temps_de_travail
            type_de_salaire
            type_de_travail
            description
            salaire
            date_de_publication
            image_de_l_employeur
            image_de_l_emploi
            auteur
            title
            extrait
            _meta {
              uid
              lang
              type
            }
          }
        }
      }
    }
  }
`

function findUniqueCompaniesAndLocations(jobs) {
  let companies = new Set()
  let locations = new Set()
  jobs.forEach(job => {
    companies.add(job.node.auteur)
    locations.add(`${job.node.city}, ${job.node.province}`)
  })
  return [companies, locations]
}

const Jobs = ({ data }) => {
  const [companyFilter, setCompanyFilter] = useState(null)
  const [locationFilter, setLocationFilter] = useState(null)
  const [dateFilter, setDateFilter] = useState(null)
  const [salaryFilter, setSalaryFilter] = useState(null)
  const [jobTypeFilter, setJobTypeFilter] = useState(null)
  const [shiftFilter, setShiftFilter] = useState(null)
  const jobs = data.prismic.allJobs.edges
  let [companies, locations] = findUniqueCompaniesAndLocations(jobs)
  let filters = {
    location: locationFilter,
    date: dateFilter,
    salary: salaryFilter,
    jobType: jobTypeFilter,
    shift: shiftFilter,
    company: companyFilter,
  }
  let jobCards = getCards(jobs, filters, false)
  function handleCompanyFilter(company) {
    setCompanyFilter(getNextFilter(companyFilter, company))
  }

  function clearFilters() {
    setCompanyFilter(null)
    setLocationFilter(null)
    setDateFilter(null)
    setSalaryFilter(null)
    setJobTypeFilter(null)
    setShiftFilter(null)
  }

  getCards(jobs, filters)
  let pageDescription = "Find the opportunity of your dreams! Applying takes less than a minute!"
  return (
    <Layout french={false}>
      <SEO
        title="Opportunities"
        lang="en"
        description={pageDescription}
      />
      <section className="jobs-section">
        <h1 className="jobs__heading">Opportunities</h1>
        <div className="filter-buttons">
          <FilterBar
            setDateFilter={setDateFilter}
            setSalaryFilter={setSalaryFilter}
            setJobTypeFilter={setJobTypeFilter}
            setShiftFilter={setShiftFilter}
            setCompanyFilter={handleCompanyFilter}
            setLocationFilter={setLocationFilter}
            companies={companies}
            locations={locations}
            dateFilter={dateFilter}
            salaryFilter={salaryFilter}
            jobTypeFilter={jobTypeFilter}
            shiftFilter={shiftFilter}
            locationFilter={locationFilter}
            companyFilter={companyFilter}
            clearFilters={clearFilters}
            french={false}
          />
        </div>
        <div className="jobs__card--container">{jobCards}</div>
      </section>
    </Layout>
  )
}

export default Jobs
